import { GuessTheSongPage } from './GuessTheSongPage.tsx';
import { useSelector } from 'react-redux';
import {GuessTheSongQuestion, selectRoom, UserAnswer} from '../../../reducers/roomReducer.ts';
import { useQuizApi } from '../../../client/useQuizApi.ts';
import { mapUserAnswersToRecord } from '../../../utils/mapUserAnswersToRecord.ts';
import { useEffect, useState } from 'react';
import { useTimer } from 'use-timer';

export const GuessTheSongPageContainer = () => {

  const room = useSelector(selectRoom);
  const { answerQuestion } = useQuizApi();
  const [url, setUrl] = useState<string | undefined>();
  const [timer, setTimer] = useState(20);

  const { time, start, pause, reset } = useTimer();

  useEffect(() => {
    if (room) {
      const question = room.question as GuessTheSongQuestion;
      try {
          setUrl(question.url.split('/').slice(-1)[0].replace('watch?v=', ''));
      } catch (e) {
          console.log(e)
      }
    }
  }, [room]);

  useEffect(() => {
    if(room) {
      if(room.state === 'QUESTION_FINISHED' && time < 4) {
        start()
      } if(room.state !== 'QUESTION_FINISHED') {
        pause()
        reset()
      }
    }
  }, [room, time]);

  useEffect(() => {
    setTimer(30);
  }, [url]);

  if (!room || !room.question || !url) {
    return <></>;
  }

  const question = room.question as GuessTheSongQuestion;

  const sorted = (answers: UserAnswer[]) => {
      return [...answers].sort((a, b) => a.timeForAnswerInMs - b.timeForAnswerInMs)
  }

  return <GuessTheSongPage
    providedAnswers={room.state === 'QUESTION_FINISHED' && time >= 4 ? sorted(room.answers) : undefined}
    timer={timer}
    songUrl={`/guess_the_song/${url}.mp3`}
    answers={{
      onAnswerSelected: (answer: string) => {
        answerQuestion(room.name, room.roundType ?? '', answer, question.id);
      },
      correctAnswer: question.correctAnswer ?? undefined,
      answers: {
        answerToUsers: 'QUESTION_FINISHED' === room.state ? mapUserAnswersToRecord(room.answers) : {},
      },
      green: {
        text: room.question.optionA,
      },
      blue: {
        text: room.question.optionB,
      },
      yellow: {
        text: room.question.optionC,
      },
      red: {
        text: room.question.optionD,
      },
    }}
  />;
};
