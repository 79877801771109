import {selectRoomName, updateRoom} from '../reducers/roomReducer.ts';
import {useAppDispatch} from '../reducers/store.ts';
import {useSelector} from 'react-redux';
import {useEffect, useRef} from 'react';
import {useQuizApi} from './useQuizApi.ts';

export const useRoomWebsocket = () => {

    const dispatch = useAppDispatch();
    const BASE_URL = import.meta.env.VITE_QUIZ_API_URL;
    const roomName = useSelector(selectRoomName);
    const {getRoom} = useQuizApi();

    const eventSourceRef = useRef<EventSource | null>(null);

    const connectSSE = () => {
        if (eventSourceRef.current) {
            // Close the previous connection before opening a new one
            eventSourceRef.current.close();
        }

        const newEventSource = new EventSource(BASE_URL + `/stream/${roomName}`);

        newEventSource.addEventListener('RoomUpdated', event => {
            const room = JSON.parse(event.data)
            dispatch(updateRoom(room));
        });

        newEventSource.onerror = function () {
            // Retry connection after a short delay
            setTimeout(() => {
                newEventSource.close();
                connectSSE();
            }, 1000);
        };

        eventSourceRef.current = newEventSource;
    }

    useEffect(() => {
        connectSSE();
    }, [roomName]);

    useEffect(() => {
        if (roomName) {
            getRoom(roomName);
        }
    }, [roomName]);

};

