import {ReactElement} from 'react';
import {cn} from "../../utils/cn.ts";

interface Props {
    children: ReactElement | ReactElement[];
    className?: string
}

export const AnimateFromTop = ({children, className}: Props) => {
    return (
        <div className={cn("ease-in", className)}>
            {children}
        </div>
    );
};
