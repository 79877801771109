import { UsersInGrid } from '../../organism/UsersInGrid.tsx';
import { ContinueButton } from '../../molecule/ContinueButton.tsx';
import { CenteredColumnLayout } from '../../templates/CenteredColumnLayout.tsx';
import { User } from '../../molecule/UserWithAvatar.tsx';
import {memo, useState} from 'react';
import {AnimateFromTop} from "../../animations/AnimateFromTop.tsx";

interface Props {
  currentUser?: User;
  users: User[];
  waiting: boolean;
  onClick?: (user: User) => void;
}

export const ChoosingPlayerToAnswer = memo(({ currentUser, users, waiting, onClick }: Props) => {

  const [selectedUser, setSelectedUser] = useState<User>(users[0]);

  return <CenteredColumnLayout justify={'justify-around'} className={'h-2/3'}>
    <div className="w-2/3 mb-12">
      <AnimateFromTop>
        <UsersInGrid currentUser={currentUser} users={users} onUserSelected={setSelectedUser} cols={'2'} />
      </AnimateFromTop>
    </div>
    {
      !waiting ? (
        <AnimateFromTop>
          <ContinueButton onClick={() => onClick && onClick(selectedUser)} text={'Wybieram'} imageUrl={selectedUser.avatarUrl}></ContinueButton>
        </AnimateFromTop>
      ) : <></>
    }
  </CenteredColumnLayout>;
}, () => true);