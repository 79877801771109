import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnimateFromTop} from "../../../animations/AnimateFromTop.tsx";
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

export const StepTwo = () => {
    usePlaySound('/cosmonada/intro_2.mp3', 1.0)

    return <AnimateFromTop className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText text={'Zanim przejdziemy do reszty zasad... Czas na żart prowadzącego!'}/>
    </AnimateFromTop>
};
