import { TypewritingText } from '../../../atom/TypewritingText.tsx';
import { AnimateFromTop } from '../../../animations/AnimateFromTop.tsx';
import { usePlaySound } from '../../../../hooks/usePlaySound.ts';

export const StepOne = () => {

    usePlaySound('/one_of_ten/intro_1.mp3', 0.6);

    return <AnimateFromTop className="flex flex-col text-center gap-2 md:gap-8">
        <TypewritingText text={'Tę rundę zaczyna osoba z najmniejszą liczbą punktów.'} />
    </AnimateFromTop>;
};
