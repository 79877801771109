import {selectUser} from "../reducers/userReducer.ts";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useSound from "use-sound";

export const usePlaySound = (path: string, volume: number) => {
    const user = useSelector(selectUser)
    const [play, {stop}] = useSound(path, {volume, interrupt: true})

    useEffect(() => {
        if (user && user.userId === 'spectator') {
            play()
        }

        return () => stop()
    }, [play, user]);

}

export const usePlaySoundIf = (path: string, volume: number, condition: () => boolean, dependencies: any[]) => {
    const user = useSelector(selectUser)
    const [play, {stop}] = useSound(path, {volume, interrupt: true})

    useEffect(() => {
        if (condition() && user && user.userId === 'spectator') {
            play()
        }

        return () => {
            stop()
        }
    }, [play, user, ...dependencies]);

}

export const usePlaySingleSound = (path: string, volume: number) => {
    const user = useSelector(selectUser)
    const [play, {stop}] = useSound(path, {volume, interrupt: true})


    return () => {
        if (user && user.userId === 'spectator') {
            play()
        }

        return () => {
            stop()
        }
    }
}
