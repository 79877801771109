import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnimateFromTop} from "../../../animations/AnimateFromTop.tsx";
import {useTimer} from "use-timer";
import {useEffect, useState} from "react";
import {GradientTypewritingText} from "../../../atom/GradientTypewritingText.tsx";
import {useSelector} from "react-redux";
import {selectUser} from "../../../../reducers/userReducer.ts";
import useSound from "use-sound";

export const Joke = () => {

    const {time, start} = useTimer();

    const user = useSelector(selectUser)
    const [playFirstPart] = useSound('/cosmonada/joke_1.mp3', {volume: 1.0, interrupt: true})
    const [playSecondPart] = useSound('/cosmonada/joke_2.mp3', {volume: 1.0, interrupt: false})
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
        start()
    }, []);

    useEffect(() => {
        if (user?.userId === 'spectator') {
            playFirstPart()
        }
    }, [user, playFirstPart]);

    useEffect(() => {
        if(time > 3 && user?.userId === 'spectator' && !isPlaying) {
            playSecondPart()
            setIsPlaying(true)
        }
    }, [user, time]);

    return <AnimateFromTop className="flex flex-col w-5/6 gap-4 md:gap-8 items-center">
        <div className="text-center">
            <TypewritingText text={'– Co to jest autosugestia?'}/>

            {
                time > 3 ? <>
                    <GradientTypewritingText
                        text={'– Jest to sugerowanie komuś, że ma auto.'}
                    />
                </> : <></>
            }
        </div>

        {
            time > 5 ?
                <div className="flex flex-col justify-center items-center w-full">
                    <AnimateFromTop>
                        <img className="h-64 aspect-square" src='/astronaut-joke.svg'/>
                    </AnimateFromTop>
                </div>
                : <></>
        }

    </AnimateFromTop>
};