import { GradientTitle } from '../molecule/GradientTitle.tsx';
import { Input } from '../atom/Input.tsx';
import { Option, Select } from '../atom/Select.tsx';
import { ContinueButton } from '../molecule/ContinueButton.tsx';
import { CenteredContentLayout } from '../templates/CenteredContentLayout.tsx';
import { useState } from 'react';
import { CreateRoomErrors } from '../../reducers/errorReducer.ts';
import { AnimateFromTop } from '../animations/AnimateFromTop.tsx';
import { LargeText } from '../atom/LargeText.tsx';

interface Props {
  scenarios: Option[],
  handleClick: (room: string, scenario: string) => void,
  errors?: CreateRoomErrors | undefined
}

export const CreateRoomPage = ({ scenarios, handleClick, errors }: Props) => {

  const [roomName, setRoomName] = useState('');
  const [option, setOption] = useState<string>('');

  return <CenteredContentLayout className="gap-8 md:gap-16">
    <GradientTitle className={'pb-2'} text={'Nowy Pokój'} data-testid="new-room-title" />
    <div className="flex flex-col justify-around space-y-8">
      <Input data-testid="type-room-name" placeholder={'Wpisz nazwę pokoju...'} onInputChanged={setRoomName} />
      <Select data-testid="select-scenario" options={scenarios} onSelectChanged={setOption}></Select>
      <div className="w-64 text-center mx-auto break-words">
        {
          errors?.chooseName ?
            <AnimateFromTop className="mt-2"><LargeText textColor={'text-red'} text={errors.chooseName} /></AnimateFromTop> : <></>
        }
        {
          errors?.chooseScenario ?
            <AnimateFromTop className="mt-2"><LargeText textColor={'text-red'} text={errors.chooseScenario} /></AnimateFromTop> : <></>
        }
      </div>
    </div>
    <ContinueButton text="STWÓRZ" onClick={() => { handleClick(roomName, option); }} />
  </CenteredContentLayout>;
};
