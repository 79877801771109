import {TypewritingText} from '../../../atom/TypewritingText.tsx';
import {AnswerButtons, AnswerButtonsProps} from '../../../organism/AnswerButtons.tsx';
import {MusicPlayingIcon} from '../../../atom/MusicPlayingIcon.tsx';
import {AnimateFromTop} from "../../../animations/AnimateFromTop.tsx";
import {usePlaySound} from "../../../../hooks/usePlaySound.ts";

interface Props {
    answers: AnswerButtonsProps;
    text?: string;
}

export const StepThree = ({answers}: Props) => {

    usePlaySound('/guess_the_song/intro_3.mp3', 1.0)

    return <AnimateFromTop className="flex flex-col w-5/6 gap-4 md:gap-12 text-center">
        <TypewritingText
            text={'Każda kolejna poprawna odpowiedź zyska o 2 punkty mniej. Im szybciej odpowiesz - tym lepiej!'}/>
        <div className="md:flex justify-center hidden">
            <MusicPlayingIcon/>
        </div>
        <div className="flex flex-col items-center justify-center">
            <AnswerButtons {...answers} clickedButton={'GREEN'}/>
        </div>
    </AnimateFromTop>;
};
