import { TypewritingText } from '../../../atom/TypewritingText.tsx';
import { AnswerButtons, AnswerButtonsProps } from '../../../organism/AnswerButtons.tsx';
import { VeryLargeGradientText } from '../../../molecule/VeryLargeGradientText.tsx';
import { Timer } from '../../../molecule/Timer.tsx';
import { AnimateFromTop } from '../../../animations/AnimateFromTop.tsx';
import { usePlaySound } from '../../../../hooks/usePlaySound.ts';

interface Props {
    question: string;
    answers: AnswerButtonsProps;
}

export const StepThree = ({ question, answers }: Props) => {

    usePlaySound('/one_of_ten/intro_3.mp3', 0.6)

    return <AnimateFromTop className="flex flex-col text-center gap-6 md:gap-16 w-3/4 mx-auto">
        <TypewritingText text={'Wyznaczona osoba zobaczy pytanie oraz 4 odpowiedzi.'} />
        <VeryLargeGradientText className="mx-auto" text={question} />
        <AnswerButtons {...answers} clickable={false} />
        <div className="flex justify-center">
            <Timer name={question} totalTimeInSeconds={5} />
        </div>
    </AnimateFromTop>;
};
